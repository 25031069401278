html
    background-color: rgb(217, 217, 217)
    font-family: sans-serif
.body_inicio
    #navbar_inicio
        display: grid
        margin-top: 2%
        margin-bottom: 5%

        .left
            grid-row: 1
            grid-column: 1
            display: flex
            justify-content: left
            align-items: center
            margin-left: 3rem
        .right
            grid-row: 1
            grid-column: 2
            display: flex
            justify-content: left
            align-items: center
            margin-top: 2%
            margin-right: 40%
            img
                display: flex
                width: 500px 
                margin-top: 1%
                padding-right: 15%
            p
                font-weight: lighter
                color: white
                font-size: 25px
                margin-right: 2%
                b
                    font-weight: 900
                .b_bigger
                    text-decoration: none
                    font-weight: 900
                    font-size: 35px

    #carousel
        display: grid
        top: 0
        width: 100%

        .carousel_one
            margin: auto
            grid-row: 1
            margin-bottom: 10%
        .carousel_two
            grid-row: 2
            margin: auto
            padding-bottom: 10%
            padding-right: 5%
            h2
                color: rgb(248, 50, 0)
                margin-bottom: 5%
        .carousel_three
            margin: auto
            padding-bottom: 10%
            padding-right: 5%
            h2
                color: rgb(248, 50, 0)
                margin-bottom: 5%
        footer
            display: grid
            grid-row: 4
            padding-bottom: 5%
            .terminos_condiciones_img_div
                grid-row: 1
                grid-column: 1/4
                width: 40%
                margin-left: 5%
                .img_tight1
                    margin-right: -12%
                .img_tight2
                    margin-right: -8%

            h2
                grid-row: 1
                grid-column: 3
                font-size: 10px
                min-width: 500px
                margin: auto
                color: black

@media only screen and (max-width: 800px)
    .body_inicio
        margin-left: 0%
        #navbar_inicio
            margin-top: 10%
            margin-bottom: 10%

            .left
                margin-left: 0rem
                margin-right: 3rem
            .right
                margin-bottom: 2%
                margin-right: 5%
                img
                    padding-right: 30%
                    width: 15%
                p
                    font-weight: lighter
                    color: white
                    font-size: 15px
                    margin-right: 2%
                    width: 200px
                    b
                        font-weight: 900
                    .b_bigger
                        text-decoration: none
                        font-weight: 900
                        font-size: 15px

        #carousel
            .carousel_one
                margin: auto
                grid-row: 1
                margin-bottom: 10%
            .carousel_two
                grid-row: 2
                margin-bottom: 10%
                h2
                    color: white
                    margin-bottom: 5%
                    margin-left: 5%
            .carousel_three
                margin-bottom: 10%
                h2
                    color: white
                    margin-bottom: 5%
                    margin-left: 5%
            .footer_carousel
                display: flex
                justify-content: center
                position: relative
                text-align: center
                .terminos_condiciones_img_div_nuevo
                    display: grid
                    
                    .img_tight1
                        grid-row: 1
                        margin-bottom: 5%
                    .img_tight2
                        grid-row: 2
                        margin-bottom: 5%
                    .img_tight3
                        grid-row: 3
                        margin-bottom: 5%
                    .h2_tight
                        grid-row: 4
                        margin-bottom: 10%
                        h2
                            color: black
                            font-size: 10px
                            font-weight: lighter

@media only screen and (max-height: 500px) 
    .body_inicio
        #navbar_inicio
            margin-top: 10%
            margin-bottom: 10%

            .left
                margin-left: 0rem
                margin-right: 3rem
            .right
                margin-bottom: 2%
                margin-right: 5%
                img
                    width: 15%
        #carousel
            .carousel_two
                margin-left: 5%
                h2
                    margin-left: 5%
            .carousel_three
                margin-left: 5%
                h2
                    margin-left: 5%
            footer
                h2
                    padding-left: 25%
                    padding-right: 25%
                    font-weight: lighter